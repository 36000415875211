.equalHousingIcon {
    width: 35px;
}

.disclaimersComponent {
    margin: 50px 0px;
}

.disclaimersTitle {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
}

.disclaimersDescription {
    /* Body */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 20.8px */
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}